<template>
  <div>
  <!-- Table Container Card -->

  <div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :max-items="itemsPerPage"
            :data="ReservatedPackages"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <span v-if="queriedItems > 0" class="mr-2 text-xl"
                  >{{ $t("Total") }} :
                </span>
                <span v-if="queriedItems > 0" class="text-primary text-xl">
                  {{ queriedItems }}
                </span>
              </div>
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Package") }}</vs-th>
              <vs-th >{{ $t("Date") }}</vs-th>
              <vs-th >{{ $t("HowManyPassengers") }}</vs-th>
              <vs-th >{{ $t("NumberOfBeneficiary") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      <b-link
                      class="font-weight-bold">
                      <div @click="GoPackageDetail(tr.ID)">
                        <p v-if="tr.MedicalPackage">
                          <u class="text-primary" style="cursor: pointer;">
                            #{{ tr.MedicalPackage.Name }}</u>
                          </p>
                        </div>
                      </b-link>                   
                    </p>
                  </vs-td>
                 
                  <vs-td>
                    <p class="product-category">
                    <p v-if="tr.PackageStartDate"> {{ tr.PackageStartDate.split("T")[0] }} </p>
                    <p v-else-if="tr.PreferrdDateFrom&&tr.PreferrdDateTo"> {{ tr.PreferrdDateFrom.split("T")[0] +'-'+ tr.PreferrdDateTo.split("T")[0] }} </p>

                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category" :dir="$vs.rtl ? 'ltr' : 'ltr'">
                      <p v-if="tr.MedicalPackage">
                        {{ tr.NumberOfCompanions }}
                      </p>
                    </p>
                  </vs-td>
                  <vs-td>
                    <p v-if="tr.MedicalPackage">
                      {{ tr.NumberOfBeneficiary }}
                    </p>                  
                  </vs-td>
             
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>




  <div class="vx-row"   v-if="viewHistory">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-center">
            <b-button   icon="icon-arrow-right"
          :icon-after="true" type="border" variant="primary" @click="getOldReservedPackages()" style="!important; margin-left: 40%"> {{
              $t("History")
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';

export default {
  components: {BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip},
  data() {
    return {
      tableColumns:[
         { key: 'Package', label: this.$t("Package"), sortable: true },
         { key: 'Date', label: this.$t("Date"), sortable: true },
         { key: 'HowManyPassengers', label: this.$t("HowManyPassengers"), sortable: true },
         { key: 'NumberOfBeneficiary', label: this.$t("NumberOfBeneficiary"), sortable: true },
        
        ],
      viewHistory:true,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      search: {},
    };
  },
  computed: {
    ReservatedPackages() {
      return this.$store.state.ReservatedPackage.reservedPackages;
    },
  },
  methods: {
    
    GoPackageDetail(ID) {
      debugger
      this.$router.push("/ReservedPackageDetail/"+ID );
    },
    getOldReservedPackages() {
      this.viewHistory=false;
        this.search.StatusID = 2; 
      this.$vs.loading();
      this.$store
        .dispatch("ReservatedPackage/SearchReservedPackage", this.search)
        .then(() => {
          this.$vs.loading.close();
                    if(this.ReservatedPackages==null||this.ReservatedPackages.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getReservatedPackages(id) {
   
      this.search.PatientId = id;
      this.search.StatusID = 1; 
      this.$vs.loading();
      this.$store
        .dispatch("ReservatedPackage/SearchReservedPackage", this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }
    this.viewHistory=true;
      debugger;
    const ID = this.$store.state.AppActiveUser.Patient.ID;
    if (ID != undefined) {
      this.getReservatedPackages(ID);
    }
  },
};
</script>


<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: rgb(232, 241, 232);
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #0814bd;
  color: rgb(253, 21, 21);
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(226, 64, 0, 0.884);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
   
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

       span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

<style>
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}

#InCS table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#InCS td,
#InCS th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#InCS th {
  border-top: none;
}

#InCS th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#InCS th:last-child {
  border-top-right-radius: 36px;
}
#InCS td:first-child,
#InCS th:first-child {
  border-left: none;
}
</style>
